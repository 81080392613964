"use client";

import {
  Flex,
  Box,
  Text,
  VStack,
  HStack
} from "@chakra-ui/react";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { SiDiscord } from "@react-icons/all-files/si/SiDiscord";
import T from "src/renderer/local-text";
import Link from "src/renderer/link";

const Contact = () => {
  return (
    <Flex p={4} justify='center' position='relative'>
      <Box p={4} bg='gray.100' rounded='10' shadow='md'>
        <Text
          mt={{ sm: 3, md: 3, lg: 5 }}
        >
          <T>Have another question? Contact us!</T>{' '}
          <Text as='span' fontWeight='bold'><T>We answer quickly.</T></Text>
        </Text>
        <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
          <VStack pl={0} spacing={3} align="stretch" fontWeight={600}>
            <HStack>
              <MdEmail />
              <Link isExternal href="mailto:hello@plurally.fr">
                hello@plurally.fr
              </Link>
            </HStack>
            <HStack display='none'>
              <SiDiscord />
              <Link href="https://discord.gg/aGpMJfYpDb" isExternal>
                Discord
              </Link>
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default Contact;
