import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

const CurvedPath = ({ points }) => {
    const [pathD, setPathD] = useState("");

    useEffect(() => {
        let newPath = ""
        if (points.every((point) => point !== null)) {
            newPath += `M ${points[0].x} ${points[0].y} `
            // First arc
            const arc1RadiusX = Math.abs(points[1].x - points[0].x) * 1; // Large radius for flatter curve
            const arc1RadiusY = Math.abs(points[1].y - points[0].y) * 1;
            newPath += `A ${arc1RadiusX} ${arc1RadiusY} 0 0 1 ${points[1].x} ${points[1].y} `;

            // Second arc

            const arc2RadiusX = Math.abs(points[2].x - points[1].x) * 1;
            const arc2RadiusY = Math.abs(points[2].y - points[1].y) * 1;
            newPath += `A ${arc2RadiusX} ${arc2RadiusY} 0 0 0 ${points[2].x} ${points[2].y} `;

            setPathD(newPath);
        }
        // // Update path on window resize
        // window.addEventListener("resize", updatePath);
        // return () => window.removeEventListener("resize", updatePath);
    }, [points]);

    return (

        <svg
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
            }}
        >
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    type: "spring",
                    duration: 4.5,
                    bounce: 0,
                    repeat: Infinity,
                    repeatType: "loop",
                    repeatDelay: 10
                }}
                strokeWidth="4"
                strokeLinecap="round"
                fill="none"
                stroke='#959BE4'
                // stroke='#3F47A8'
                d={pathD}
            />
        </svg>
    );
};

export default CurvedPath;
