"use client";

import {
  Box,
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  GridItem,
  Image,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import T from "src/renderer/local-text";
import { ContactApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import translate from "src/locales/translate";
import { usePageContext } from "vike-react/usePageContext";
import Link from "src/renderer/link";


const CONTACT_API = new ContactApi(getAxiosParams(), undefined, axios)

const CRMForm = () => {
  const [selectedCRM, setSelectedCRM] = React.useState('');

  const handleCRMChange = (event) => {
    setSelectedCRM(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    CONTACT_API.contactNewApiV1ContactContactNewPost(
      e.target.firstname.value,
      e.target.lastname.value,
      e.target.email.value,
      e.target.company.value,
      selectedCRM === 'Other' ? e.target.otherCRM.value : selectedCRM,
    )
  };

  return (
    <Box maxW="400px" mx="auto" bg='white' p='12' rounded='10' shadow='xl'>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="firstname" isRequired>
            <FormLabel><T>First name</T></FormLabel>
            <Input placeholder="Nathalie" />
          </FormControl>

          <FormControl id="lastname" isRequired>
            <FormLabel><T>Last name</T></FormLabel>
            <Input placeholder="Doe" />
          </FormControl>

          <FormControl id="email" isRequired type="email">
            <FormLabel><T>Email</T></FormLabel>
            <Input placeholder="nathalie@abc.com" />
          </FormControl>

          <FormControl id="company" isRequired>
            <FormLabel><T>Company</T></FormLabel>
            <Input placeholder="ABC Corp" />
          </FormControl>

          <FormControl id="crm" isRequired>
            <FormLabel><T>CRM</T></FormLabel>
            <Select placeholder={<T>Select CRM</T>} onChange={handleCRMChange}>
              <option value="Salesforce">Salesforce</option>
              <option value="HubSpot">HubSpot</option>
              <option value="Other"><T>Other</T></option>
            </Select>
          </FormControl>

          {selectedCRM === 'Other' && (
            <FormControl id="otherCRM" isRequired>
              <FormLabel><T>Specify CRM</T></FormLabel>
              <Input placeholder='CRM' />
            </FormControl>
          )}
          <Button type="submit" colorScheme="brand" width="full" >
            <T>Submit</T>
          </Button>
        </VStack>
      </form>
    </Box>
  );
};


const Pricing = () => {
  const { locale } = usePageContext();
  const subject = encodeURIComponent(translate('_lp:contact:subject', locale));
  const body = encodeURIComponent(translate('_lp:contact:body', locale));
  return (
    <Box mb='4' bg='brand.500'>
      <SimpleGrid columns={12}>
        <GridItem colSpan={{ base: 12, lg: 6 }} display={{ base: 'none', lg: 'inherit' }}>
          <Box position='relative'>
            <Image
              position='absolute'
              top='0'
              left='0'
              right='0'
              bottom='0'
              w='full'
              h='full'
              transform='scaleX(-1)'
              objectFit='cover'
              objectPosition='top'
              src={`/landing/group.jpeg`}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }} >
          <VStack className="landing-vstack" spacing={8} textAlign={{ base: 'center', lg: 'start' }}>
            <Heading size='xl' >
              <T>_lp:pricing:title</T>
            </Heading>
            <Text>
              <T>
                _lp:pricing:desc
              </T>
            </Text>
            <Link href={`mailto:hello@plurally.fr?subject=${subject}&body=${body}`} isExternal>
              <Button size='xl' bg='brandTernary.200'>
                <T>Contact sales</T>
              </Button>
            </Link>
          </VStack>
        </GridItem>
      </SimpleGrid>
      <Image
        w='full'
        h='full'
        transform='scaleX(-1)'
        objectFit='cover'
        objectPosition='top'
        src={`/landing/group.jpeg`}
        display={{ base: 'inherit', lg: 'none' }}
      />
    </Box >
  );
};

export default Pricing;
