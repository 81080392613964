import { motion, useTransform, useScroll, useMotionValueEvent, AnimatePresence, useTime, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Image,
  Container,
  HStack,
  Icon,
  Text,
  SimpleGrid,
  Flex,
  GridItem
} from "@chakra-ui/react";
import { FaArrowRight, FaCheckCircle, FaMicrophone } from "react-icons/fa";
import T from "src/renderer/local-text";
import LandingContainer from "src/home/LandingContainer";
import CurvedPath from "./curved-path";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { usePageContext } from "vike-react/usePageContext";
import translate from "src/locales/translate";



const micLogos = [
  '/logos/iphone-voice-recorder.png',
  '/logos/teams.png',
  '/logos/zoom.webp',
  // 'logos/meet.png',
  '/logos/leexi_logo.jpeg',
]

const crmLogos = [
  '/logos/hubspot.svg',
  '/logos/portwin.png',
  '/logos/salesforce.svg',
  '/logos/pipedrive.png',
]

const getRefCoords = (parentRef, childRef) => {
  if (!parentRef.current || !childRef.current) return null
  const rect = childRef.current.getBoundingClientRect();
  const parentRect = parentRef.current.getBoundingClientRect();
  return {
    x: rect.x + rect.width / 2 - parentRect.x,
    y: rect.y + rect.height / 2 - parentRect.y
  }
}


const HorizontalScrollCarousel = () => {
  const { locale } = usePageContext()
  const [line, setLine] = useState(null);
  const [micLogoIx, setMicLogoIx] = useState(0);
  const [crmLogoIx, setCrmLogoIx] = useState(0);
  const [micCoords, setMicCoords] = useState(null);
  const [logoCoords, setLogoCoords] = useState(null);
  const [crmCoords, setCrmCoords] = useState(null);
  const [isInViewTime, setIsInViewTime] = useState(null);

  const lines = translate('_lp:marketing_demo:text', locale).split('\n');
  const actions = translate('_lp:marketing_demo:actions', locale).split('\n')

  const parentRef = useRef(null);
  const targetRef = useRef(null);
  const refMic = useRef(null);
  const refLogo = useRef(null);
  const refCrm = useRef(null);
  const isInView = useInView(refLogo)

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end end"],
  });

  const time = useTime();


  useMotionValueEvent(time, "change", (latest) => {
    if (isInView && !isInViewTime) {
      setIsInViewTime(latest);
    }

    if (isInViewTime) {
      const currentLine = Math.floor(((latest - isInViewTime) % 60000) / 60000 * lines.length);
      setLine(currentLine);
      const currentMicLogoIx = Math.floor(((latest - isInViewTime) % 40000) / 40000 * micLogos.length);
      setMicLogoIx(currentMicLogoIx);
      const currentCrmLogoIx = Math.floor(((latest - isInViewTime) % 60000) / 60000 * crmLogos.length);
      setCrmLogoIx(currentCrmLogoIx);
    }
  })


  useEffect(() => {

    // Create a ResizeObserver to monitor changes
    const calculateCoords = () => {
      setMicCoords(getRefCoords(parentRef, refMic));
      setLogoCoords(getRefCoords(parentRef, refLogo));
      setCrmCoords(getRefCoords(parentRef, refCrm));
    }
    const resizeObserver = new ResizeObserver(calculateCoords);

    if (parentRef.current) resizeObserver.observe(parentRef.current);
    if (refMic.current) resizeObserver.observe(refMic.current);
    if (refLogo.current) resizeObserver.observe(refLogo.current);
    if (refCrm.current) resizeObserver.observe(refCrm.current);

    // Initial calculation
    calculateCoords();

    return () => {
      resizeObserver.disconnect(); // Cleanup
    };
  }, []);

  // const rotate = useTransform(time, [0, 4000], [0, 360], { clamp: false });
  return (
    <Box
      // borderRadius={{ base: 10, lg: 100 }}
      // borderWidth='8px'
      // shadow='xl'
      borderColor='#2e2d2b'
      bg='#ffffff88'>
      <Box position='relative' ref={parentRef} h='max(55vh, 600px)'>
        <VStack
          spacing={4}
          position='absolute'
          align='end'
          top='0%'
          right='0%'>
          {/* <Text fontSize={{ base: 'inherit', lg: 'lg' }} fontWeight='bold' textAlign='end'>
            <T>
              Input audio from voice note or recorded meeting
            </T>
          </Text> */}
          <HStack
            zIndex={2}
            spacing={8}
            as={motion.div}
            justify='end'
            align='center'
          // transform={'translate(50%, 50%)'}
          >
            <Box
              maxW='min(400px, 70%)'
            >
              <AnimatePresence mode="popLayout">
                <motion.div
                  key={line}
                  layout
                  initial={{ opacity: 0, y: 50, scale: 0.3 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                  <HStack spacing={4} >
                    <Icon as={FaMicrophone} boxSize={{ base: 8, lg: 12 }} color='#FF0000' />
                    <HStack
                      // borderWidth='2px'
                      p={4}
                      rounded='10'
                      // borderColor='#2e2d2b'
                      // bg='white'
                      color='#2e2d2b'
                    // shadow='xl'
                    // boxShadow='8px 8px 0px 0px #2e2d2b'
                    >
                      <Text fontSize={{
                        base: 'sm',
                        lg: 'lg',
                      }} fontWeight='bold' textAlign='center' >
                        {lines[line]}
                      </Text>
                    </HStack>
                  </HStack>
                </motion.div>
              </AnimatePresence>
            </Box>
            <VStack
              ref={refMic}
              key={micLogoIx}
              width={{ base: '80px', lg: '130px' }}
              height={{ base: '80px', lg: '130px' }}
              justify='center'
            >
              <VStack
                w='full'
                h='full'
                justify='center'
                bg='white'
                shadow='xl'
                borderRadius='full'
                borderWidth='3px'
                p={{ base: 4, lg: 6 }}
              >
                <Image src={micLogos[micLogoIx]} />
              </VStack>
            </VStack>
          </HStack>
        </VStack>
        <VStack

          zIndex={1}
          ref={refLogo}
          width={{ base: '100px', lg: '150px' }}
          height={{ base: '100px', lg: '150px' }}
          position='absolute'
          top='50%'
          left='50%'
          transform='translate(-50%, -50%)' >
          <VStack
            w='full'
            h='full'
            justify='center'
            className="animated-border"
            borderRadius='full'
            p={{ base: 6, lg: 4 }}
          // borderWidth={4}
          >
            <Image src='logo-black-square.svg' display={{ base: 'inherit', lg: 'none' }} />
            <Image src='logo-black.svg' display={{ base: 'none', lg: 'inherit' }} />
          </VStack>
        </VStack>
        <VStack

          spacing={4}
          position='absolute'
          bottom='0%'
          align='start'
          left='0%'>
          <HStack
            zIndex={2}
            align='top'
            spacing={4}
          // transform={'translateY(-50%)'}
          >
            <VStack
              ref={refCrm}
              key={crmLogoIx}
              width={{ base: '80px', lg: '140px' }}
              height={{ base: '80px', lg: '140px' }}
              justify='center'>
              <VStack
                w='full'
                h='full'
                justify='center'
                bg='white'
                shadow='xl'
                borderRadius='full'
                borderWidth='3px'
                p={{ base: 4, lg: 6 }}
              >
                <Image src={crmLogos[crmLogoIx]} />
              </VStack>
            </VStack>
            <HStack ms='4'>
              <AnimatePresence initial={false} mode="popLayout">
                <motion.div
                  key={line}
                  layout
                  initial={{ opacity: 0, y: 50, scale: 0.3 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                  {actions[line] && <HStack>
                    <Icon
                      as={FaCheck}
                      rounded='full'
                      color='#38a169'
                      boxSize={{
                        base: 8,
                        lg: 12,
                      }} />
                    <HStack fontSize={{ base: 'sm', lg: 'xl' }}
                      borderRadius={10}
                      bg='white'
                      color='#2e2d2b'
                      // borderWidth='4px'
                      // borderColor='#38a169'
                      p={4} >
                      <Text fontWeight='black'>
                        {actions[line]}
                      </Text>
                    </HStack>
                  </HStack>
                  }
                </motion.div>
              </AnimatePresence>
            </HStack>
          </HStack>
          {/* <Text fontSize={{ base: 'inherit', lg: 'lg' }} fontWeight='bold' textAlign='end'>
            Plurally automatically syncs your CRM
          </Text> */}
        </VStack>
        <Box>
          <CurvedPath points={[micCoords, logoCoords, crmCoords]} />
        </Box>
      </Box>
    </Box >
  );
};

export default HorizontalScrollCarousel;
