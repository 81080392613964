import { Box } from '@chakra-ui/react';
import { memo } from 'react';
import T from 'src/renderer/local-text';

function AnnotationNode({ data }) {
    return (
        <>
            <Box fontSize='sm' display='flex' p={4}>
                <div style={{ marginRight: 4 }}>{data.level}.</div>
                <div>
                    <T>
                        {data.label}
                    </T>
                </div>
            </Box>
            {data.arrowStyle && (
                <div className="arrow" style={data.arrowStyle}>
                    ⤹
                </div>
            )}
        </>
    );
}

export default memo(AnnotationNode);
