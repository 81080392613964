import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  Image as ChakraImage,
  Flex,
  VStack,
  Avatar,
  HStack,
  SimpleGrid,
  GridItem,
  Icon,
  Button,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Image,
} from "@chakra-ui/react";
import Link from "src/renderer/link";

import Pricing from "src/home/pricing";
import Cta from "../../home/Cta";
import LandingContainer from "../../home/LandingContainer";
import { ReactFlowProvider } from "@xyflow/react";
import React from "react";

import { FaCaretRight } from "@react-icons/all-files/fa/FaCaretRight";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import { FaHammer } from "@react-icons/all-files/fa/FaHammer";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";

import { useData } from "vike-react/useData";
import { ExploreCard, TEMPLATES_BGS } from "src/components/explore";
import { createCustomNodes } from "src/appUtils";
import Faq from "src/components/faq";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { FaRocket } from "@react-icons/all-files/fa/FaRocket";
import T from "src/renderer/local-text";
import AnimatedFlow from "src/components/demo-animated";
import { FaCheckCircle, FaWatchmanMonitoring } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Demo from "src/components/demo";
import ScrollingMarketingComponent from "src/components/marketing";
import translate from "src/locales/translate";
import { usePageContext } from "vike-react/usePageContext";
import CostSimulator from "src/components/cost-simulator-form";

const HeroTitle = ({ children }) => (
  <Heading
    as='h1'
    // lineHeight={0.9}
    // fontWeight="bold"
    letterSpacing='1px'
    fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
    fontWeight='black'
    w='full'
    textAlign={{ base: 'center', lg: 'start' }}
  >
    {children}
  </Heading>
);

const LazyVideo = () => {
  return <LiteYouTubeEmbed
    noCookie
    allowFullScreen
    className='video'
    style={{
      width: '100%',
      height: '100%',
      border: 'none',
    }}
    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
    id="Ilka2CUDGQ0"
    playlabel="Plurally Demo Video" />
}

const Hero1 = () => {
  const { locale } = usePageContext()
  return (
    <LandingContainer id="demo" px='0' >
      <SimpleGrid
        pt='20'
        spacingX={{ base: 8, lg: 0 }}
        spacingY={{ base: 2, lg: 40 }}
        columns={{ base: 1, lg: 12 }}
        backgroundImage={{ base: 'linear-gradient(180deg, #ffffff00 0%, #ffffff 10%, #ffffff 35%)', lg: 'none' }}
      >
        <GridItem display={{ base: 'none', lg: 'block' }} colSpan={{ base: 12, md: 6, lg: 7 }} />
        <GridItem
          as={VStack}
          colSpan={{ base: 12, lg: 4, xl: 5 }}
          // spacing={{ base: 4, lg: 8 }}
          px={{ base: 4, lg: 0 }}
          // py={{ base: 8, lg: 0 }}
          // justify="center"
          justify='center'
          position="relative"
          overflow="hidden" // Ensures the background doesn't overflow
          rounded='10'
          zIndex='1'
        >
          <VStack
            align={{
              base: "center",
              lg: "start"
            }}
            spacing={8}
          >
            <VStack align={{ base: 'center', lg: 'start' }} px={{ base: 12, lg: 0 }}>
              <HStack display={{ base: 'none', lg: 'inherit' }} fontSize='sm' color='gray.600' p='2' fontWeight='bold' rounded='100' bg='white'>
                <Icon as={FaCheckCircle} color='green.500' />
                <Text>
                  <T>_lp:hero1:sub</T>
                </Text>
              </HStack>
              <Tag display={{ base: 'inherit', lg: 'none' }} rounded='100' bg='brand.50' size='sm'>
                <Text>
                  <T>_lp:hero1:sub</T>
                </Text>
              </Tag>
              <HeroTitle >
                <T>_lp:hero1:1</T><br />
              </HeroTitle>
            </VStack>
            <Text textAlign={{ base: 'center', lg: 'start' }}>
              <T>_lp:h1:sub</T>
              {/* <T>_lp:h1</T> */}
            </Text>
            <Box display={{ base: 'none', lg: 'inherit' }}>
              <Cta size="xl" withDemo />
            </Box>
          </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}
          order={{ base: 1, lg: 0 }}
          borderTopLeftRadius={100}
          ps={{ base: 0, lg: 20 }}
          bg='#ffffff88'

          borderLeftWidth={{ base: 0, lg: '1px' }}
        >
          <VStack justify='center' h='full' align={{ base: 'center', lg: 'stretch' }} p='4' spacing={8} >
            <VStack display={{ base: 'inherit', lg: 'none' }} spacing={4}>
              <MarketBlock title={<T>_lp:hero1:title</T>} desc={<T>_lp:hero1:p</T>} />
              <Cta size='xl'>
                _lp:hero1:cta
              </Cta>
            </VStack>
            <Box display={{ base: 'none', lg: 'inherit' }}>
              <MarketBlock title={<T>_lp:hero1:title</T>} desc={<T>_lp:hero1:p</T>} cta='_lp:hero1:cta' />
            </Box>
          </VStack>
        </GridItem>
        <GridItem
          id='use-cases'
          scrollMarginTop='100px'
          colSpan={{ base: 12, lg: 8 }}
          rounded={{ base: 0, lg: 100 }}>
          <ScrollingMarketingComponent />
        </GridItem>
      </SimpleGrid>
    </LandingContainer >
  );
};

const MarketBlock = ({ title, desc, cta }) => {
  return (
    <VStack align={{ base: 'center', lg: 'stretch' }} spacing='4'>
      <Heading
        as='h1'
        textAlign={{ base: 'center', lg: 'start' }} >
        {title}
      </Heading>
      <Text textAlign={{ base: 'center', lg: 'start' }}>
        {desc}
      </Text>
      {cta && <Box display={{ base: 'none', lg: 'inherit' }}>
        <Cta size='xl' withDemo>
          {cta}
        </Cta>
      </Box>}
    </VStack>
  )
}

const IntegrationLogos = () => {
  const { locale } = usePageContext()
  const boxSize = { base: '50px', lg: '100px' }
  return <LandingContainer>
    <SimpleGrid columns={12} spacingX={8} spacingY={14} >
      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <MarketBlock title={<T>_lp:integrations:title</T>} desc={<T>_lp:integrations:p</T>} cta={'_lp:integrations:cta'} />
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <VStack align="center" justify='center' h='full' spacing='8' >
          <SimpleGrid justify="center" align="center" wrap="wrap" spacing={{ base: 16, md: 16, lg: 20 }} columns={3}>
            <Box>
              <ChakraImage src="/logos/portwin.png" alt="Portwin" boxSize={boxSize} />
            </Box>
            <Box>
              <ChakraImage src="/logos/iphone-voice-recorder.png" alt="Slack" boxSize={boxSize} />
            </Box>
            <Box>
              <ChakraImage src="/logos/hubspot.svg" alt="Hubspot" boxSize={boxSize} />
            </Box>
            <Box>
              <ChakraImage src="/logos/teams.png" alt="Microsoft Teams" boxSize={boxSize} />
            </Box>
            {/* <Box>
        <ChakraImage src="/logos/openai.svg" alt="OpenAI" boxSize={boxSize} />
      </Box> */}
            <Box>
              <ChakraImage src="/logos/salesforce.svg" alt="Salesforce" boxSize={boxSize} />
            </Box>
            <Box>
              <ChakraImage src="/logos/slack.svg" alt="Slack" boxSize={boxSize} />
            </Box>
            <Box>
              <ChakraImage src="/logos/gmail.svg" alt="GMail" boxSize={boxSize} />
            </Box>
            <Box>
              <ChakraImage src="/logos/leexi_logo.jpeg" alt="Leexi" boxSize={boxSize} />
            </Box>
            <Box>
              <ChakraImage src="/logos/zoom.webp" alt="Zoom" boxSize={boxSize} />
            </Box>
          </SimpleGrid>
          <Text fontWeight="bold" textColor="gray.400" mb='4' >
            <T>
              _lp:integrations:more
            </T>
          </Text>
          <Box display={{ base: 'inherit', lg: 'none' }}>
            <Cta size='xl' withDemo>
              _lp:integrations:cta
            </Cta>
          </Box>
        </VStack>
      </GridItem>
    </SimpleGrid>
  </LandingContainer>
}

const Step = ({
  icon,
  title,
  text,
  selected,
  onClick
}) => {
  const color = selected ? 'brand.500' : 'inherit'
  return <Flex
    borderStartColor={color}
    borderStartWidth={selected ? '2px' : '0px'}
    onClick={onClick}
    justify='space-between'
    align={'center'}
    gap='4'
    _hover={{ bg: 'brand.50' }}
    p={4}
    cursor='pointer'>
    <Flex w={12} h={12} align={'center'} justify={'center'} rounded={'full'} >
      <Icon as={icon} w='6' h='6' color={color} />
    </Flex>
    <Box flex='1'>
      <Heading size='md' as='h3' color={color}>
        {title}
      </Heading>
      <Text color={selected ? 'inherit' : 'gray.600'}>{text}</Text>
    </Box>
    <Icon as={FaCaretRight} color={color} />
  </Flex>
}

const Step1 = ({ onClick }) => {
  const INTEGRATIONS = [
    ['hubspot', 'HubSpot'],
    ['gmail', 'Gmail'],
    ['salesforce', 'Salesforce'],
    ['gsheets', 'Google Sheets'],
    ['slack', 'Slack'],
    ['instagram', 'Instagram'],
    // ['shopify', 'Shopify'],
  ]
  const [integration, setIntegration] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIntegration(prevIntegration => (prevIntegration + 1) % INTEGRATIONS.length);
    }, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  return (<Flex className="lp-card" borderColor={useColorModeValue("gray.300", "gray.500")} direction='column' align='center' gap={4} position='relative' p='4' >
    <Tag colorScheme="brand" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
      <Icon as={FaLink} />
      <Text>
        <T>
          Integrate your services
        </T>
      </Text>
    </Tag>
    <VStack p={{ base: 4, lg: 8 }} align='stretch'>
      <HStack align='center'>
        <Avatar borderWidth='2px' borderColor='gray.200' size={{ base: 'lg', lg: 'xl' }} src={`/logos/${INTEGRATIONS[integration][0]}.svg`} aria-label={`${INTEGRATIONS[integration][0]} logo`} name={`${INTEGRATIONS[integration][0]} logo`} />
        <Icon as={FaArrowRight} color='gray.400' fontSize='2xl' />
        <Avatar borderWidth='2px' borderColor='gray.200' size={{ base: 'lg', lg: 'xl' }} src={`/logo-square-white.jpg`} aria-label='Plurally logo' name='Plurally logo' />
      </HStack>
      <Heading>Plurally</Heading>
      <Text><T>_lp:step1:p1:1</T>{' '}<Text fontSize='2xl' fontWeight='bold' as='span'>{INTEGRATIONS[integration][1]}</Text><T>_lp:step1:p1:2</T></Text>
      <Text>
        <T>
          _lp:step1:p2
        </T>
      </Text>
      <HStack w='full' justify='end' mt='2'>
        <Button colorScheme="brand" onClick={onClick}>
          <T>
            Continue
          </T>
        </Button>
      </HStack>
    </VStack>
  </Flex >)
}

const TableRow = ({ type, head, details }) => {
  return (
    <AccordionItem>
      <AccordionButton
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        p={4}>
        <Text align='start' fontWeight='bold' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
          <T>{head}</T>
        </Text>
        <HStack>
          <Tag bg={{
            Created: 'green.100',
            Updated: 'blue.100',
            Associated: 'purple.100'
          }[type]} >{type}</Tag>
          <ChevronDownIcon visibility={!details && 'hidden'} fontSize="24px" />
        </HStack>
      </AccordionButton>
      {details && <AccordionPanel pb={4}>
        <TableContainer whiteSpace='wrap' overflow='auto'>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <T>
                    Field
                  </T>
                </Th>
                {
                  type === 'Updated' && <Th>
                    <T>
                      Old Value
                    </T>
                  </Th>
                }
                <Th>
                  <T>
                    New Value
                  </T>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                Object.entries(details).map(([key, value]) => <Tr key={key}>
                  <Td>
                    <T>
                      {key}
                    </T>
                  </Td>
                  {type === 'Updated' ? <>
                    <Td>
                      <T>
                        {value[0]}
                      </T>
                    </Td>
                    <Td>
                      <T>
                        {value[1]}
                      </T>
                    </Td>
                  </> :
                    <Td>
                      <T>
                        {value}
                      </T>
                    </Td>}
                </Tr>
                )
              }
            </Tbody>
          </Table>
        </TableContainer>
      </AccordionPanel>}
    </AccordionItem>
  )
}

const UseCase = ({ useCase, ...props }) => {
  return (<Box className="lp-card" height={"500px"} width={"full"} position='relative' p='4'>
    <Box
      className='lp-bg-img'
      {...props}
    />
    <Box bg='white' h='full' w='full' rounded='10' shadow='md'>
      <ReactFlowProvider>
        <Demo useCase={useCase} />
      </ReactFlowProvider>
    </Box>
  </Box>)
}

const UseCases = () => {
  return (
    <LandingContainer
      color='white'
      subtitle={<T>Use cases</T>}
      title={<T>_lp:use_cases:title</T>}
      desc={<T>
        _lp:use_cases:p
      </T>}>
      <Tabs align='center' size='lg' variant='unstyled' gap='8'>
        <TabList gap='4'>
          {[['_demo:automatic_sync_after_call:title', '#DCDEF680'], ['_demo:automatic_inbound_qualification:title', '#e0f6de80']].map(([tab, bg]) => (
            <Tab key={tab} shadow='md' rounded='10' backdropFilter='blur(10px)' _selected={{ fontWeight: 'bold', bg }}>
              <T>{tab}</T>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {
            [
              {
                useCase: 'automatic_sync_after_call',
                backgroundImage: `url('/landing/bg14.jpeg')`,
                filter: 'hue-rotate(30deg)'
              },
              {
                useCase: 'automatic_lead_qualification',
                backgroundImage: `url('/landing/bg13.jpeg')`,
                filter: 'hue-rotate(-40deg)'
              }
            ].map((props) => (
              <TabPanel key={props.useCase} px={{ base: 0, lg: 4 }}>
                <UseCase {...props} />
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </LandingContainer >
  )
}

const GettingStarted = () => {

  React.useEffect(() => {
    // Preload images
    const images = ['/logo-square-white.jpg'];
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const [currentStep, setCurrentStep] = React.useState(0)
  const borderColor = useColorModeValue("gray.300", "gray.500")
  return (
    <LandingContainer px={{ base: 2, sm: 4, lg: 16 }} title={<T>Getting started</T>}>
      <Text mb='8'>
        <T>
          _lp:getting_started:p
        </T>
      </Text>
      <SimpleGrid columns={12} rowGap={12} columnGap={{ base: 0, lg: 12 }}>
        <GridItem colSpan={{ base: 12, lg: 4 }} as={VStack}>
          <VStack align='stretch' py={{ base: 0, lg: 12 }} h='full' justify='center'>
            <Step onClick={() => setCurrentStep(0)} icon={FaHammer} title={<T args={{ step: 1 }}>{'Step {step}'}</T>} text={<T>Create a flow by using a template or from scratch</T>} selected={currentStep === 0} />
            <Step onClick={() => setCurrentStep(1)} icon={FaLink} title={<T args={{ step: 2 }}>{'Step {step}'}</T>} text={<T>Connect the necessary integrations</T>} selected={currentStep === 1} />
            <Step onClick={() => setCurrentStep(2)} icon={FaWatchmanMonitoring} title={<T args={{ step: 3 }}>{'Step {step}'}</T>} text={<T>Monitor your flow activity</T>} selected={currentStep === 2} />
          </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 8 }}>
          <HStack w='full' h='500px' align={{ base: 'start', lg: 'center' }} justify='center'>
            {currentStep === 0 && <Box className="lp-card" height={"500px"} width={"full"} position='relative'>
              <Tag colorScheme="brandQuaternary" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
                <Icon as={FaHammer} />
                <Text>
                  <T>
                    Build your flow
                  </T>
                </Text>
              </Tag>
              <ReactFlowProvider>
                <AnimatedFlow onClick={() => setCurrentStep(1)} />
                {/* <Demo/> */}
              </ReactFlowProvider>
            </Box>}
            {
              currentStep === 1 && <Step1 onClick={() => setCurrentStep(2)} />
            }
            {
              currentStep === 2 &&
              <Flex className="lp-card" direction='column' align='center' gap={4} position='relative' p='4' w='full' >
                <Tag colorScheme="green" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
                  <Icon as={FaRocket} />
                  <Text>
                    <T>
                      Your flow is running!
                    </T>
                  </Text>
                </Tag>
                <VStack w='full' h='500px' align='stretch' p={4} >
                  <Box>
                    <Text mb='4'>
                      <T>
                        _lp:animated_demo:monitoring:p
                      </T>
                    </Text>
                  </Box>
                  <Accordion w="full" h='500px' rounded="lg" defaultIndex={[0]} overflowY='auto' allowToggle>
                    {[
                      {
                        type: 'Created',
                        head: '_lp:animated_demo:call_created:head',
                        details: {
                          '_hubspot:hs_timestamp': '2024-11-15 12:15:00',
                          '_hubspot:hs_call_title': '_lp:animated_demo:call_created:hs_call_title',
                          '_hubspot:hs_call_body': '_lp:animated_demo:call_created:hs_call_body',
                        }
                      },
                      {
                        type: 'Created',
                        head: '_lp:animated_demo:contact_created:head',
                        details: {
                          'First name': 'John',
                          'Last name': 'Doe',
                          'Email': 'john@supacompany.com',
                          'Role': 'COO',
                        }
                      },
                      {
                        type: 'Associated',
                        head: '_lp:animated_demo:contact_company_assoc:head',
                      },
                      {
                        type: 'Associated',
                        head: '_lp:animated_demo:contact_call_assoc:head',
                      },
                      {
                        type: 'Associated',
                        head: '_lp:animated_demo:contact_deal_assoc:head',
                      },
                      {
                        type: 'Updated',
                        head: '_lp:animated_demo:deal_updated:head',
                        details: {
                          'Amount': ['_lp:animated_demo:deal_updated:amount:before', '_lp:animated_demo:deal_updated:amount:after'],
                          'Close date': ['2024-12-12', '2025-01-18']
                        }
                      },
                    ].map(({ type, head, details }) => <TableRow key={head} type={type} head={head} details={details} />)}
                  </Accordion>
                </VStack>
              </Flex>
            }
          </HStack >
        </GridItem>
      </SimpleGrid>
    </LandingContainer >
  );
};

const CostSimulator1 = () => {

  return (
    <Box bg='brand.500'>
      <LandingContainer >
        <SimpleGrid columns={12} spacingX={8} spacingY={14} >
          <GridItem color='white' colSpan={{ base: 12, lg: 6 }} pt={{ base: 0, lg: 16 }}>
            <MarketBlock title={<T>_lp:simulator:title</T>} desc={<T>_lp:simulator:desc</T>} />
          </GridItem>
          {/* <GridItem colSpan={{ lg: 1 }} display={{ base: 'none', lg: 'inherit' }} /> */}
          <GridItem colSpan={{ base: 12, lg: 6 }} ms={{ base: 0, lg: 12 }} >
            <Box bg='white' rounded='16' p='8'
            // backgroundImage='linear-gradient(90deg, #F7B1D2, #727ADB, #F7B1D2)'
            >
              <CostSimulator />
            </Box>
          </GridItem>
          <GridItem colSpan={{ lg: 3 }} display={{ base: 'none', lg: 'inherit' }} />
        </SimpleGrid>
      </LandingContainer>
    </Box>
  );
};


const Templates = () => {
  const { templates, nodeTypes } = useData()
  const customNodes = React.useMemo(() => createCustomNodes({ nodeTypes, isDemo: true, defaultIsExpanded: false }), [nodeTypes])
  return <LandingContainer title="Our most popular templates 🔥">
    <VStack align='stretch'>
      <SimpleGrid
        columns={{ base: 1, lg: 3 }}
        spacing={{ base: 8 }}
      >
        {
          templates?.slice(0, 3).map(({ template, edges, nodes }, ix) => <ExploreCard
            root="/gallery"
            key={template.id}
            template={template}
            edges={edges}
            nodes={nodes}
            customNodes={customNodes}
            bg={TEMPLATES_BGS[ix % TEMPLATES_BGS.length]}
          />)
        }
      </SimpleGrid>
      <HStack justify={{ base: 'center', lg: 'end' }} spacing={4}>
        <Button mt='2' variant='link' leftIcon={<MdChevronRight />}>
          <Link href='/gallery'>
            See more templates
          </Link>
        </Button>
      </HStack>
    </VStack>
  </LandingContainer>
}


const LandingPage = () => {
  return (
    <>
      <VStack w='full' align='stretch' spacing={0}>
        <VStack position='relative'
          minH='100vh'
          justify='center'
        >
          <Box
            className="lp-bg-img"
            display={{ base: 'none', lg: 'block' }}
            w='full'
            maxH='100vh'
            position='absolute'
            top='0'
            left='0'
            // transform='scaleX(-1)'
            backgroundPosition='top'
            backgroundImage={`linear-gradient(90deg, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 25%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%), linear-gradient(188deg, rgba(255,255,255,0) 65%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%)`}
            zIndex='-1'
          />
          <Image src="/landing/lp-bg-main.jpeg"
            // transform='scaleX(-1)'
            display={{ base: 'none', lg: 'block' }}
            objectFit='cover'
            transform='translateX(-15%)'
            objectPosition='top'
            w='full'
            maxH='100vh'
            position='absolute' top='0' left='0' zIndex='-2'
          />
          <Image src="/landing/lp-bg-main-color.jpeg"
            // transform='scaleX(-1)'
            display={{ base: 'block', lg: 'none' }}
            objectFit='cover'
            // transform='translateX(-15%)'
            objectPosition='top'
            w='full'
            // maxH='100vh'
            position='absolute' top='0' left='0' zIndex='-2'
          />

          <Hero1 />
        </VStack>
        {/* <Box id="getting-started" scrollMarginTop="100px">
          <GettingStarted />
        </Box> */}
        <Box id="cost-simulator" scrollMarginTop="100px" position='relative' >
          <CostSimulator1 />
        </Box>

        <Box id="integrations" scrollMarginTop="100px" position='relative'>
          <IntegrationLogos />
          {/* <RequestIntegration /> */}
        </Box>

        <Box id="templates" scrollMarginTop="100px" display='none'>
          {/* <Templates /> */}
        </Box>
        {/* <Box id="testimonials" scrollMarginTop="100px" display='none'>
          <Testimonials />
        </Box> */}
        {/* <Box id="use-cases">
        <UseCases />
      </Box> */}
        {/* <Box id="features">
        <Features />
      </Box> */}
        <Box id="pricing" scrollMarginTop="100px">
          <Pricing />
        </Box>
        {/* <Box id="integrations" scrollMarginTop="100px">
          <IntegrationsWidget />
        </Box> */}
      </VStack >
      <Box id="faq" scrollMarginTop="100px" position='relative'>
        <Faq />
      </Box>
    </>
  );
};

export default LandingPage;
